import React, { useState } from "react";
import { Accordion, Card, Button } from "react-bootstrap";

const Demo = () => {
  return (
    <div className="accordion-parent">
      <div className="demo-title">Specialty Services</div>
      <div className="line"></div>
      <Accordion defaultActiveKey={["0"]} flush className="accordion-container">
        <Accordion.Item eventKey="0" className="accordion-item">
          <Accordion.Header className="accordion-item-header">
            Brush Removal
          </Accordion.Header>
          <Accordion.Body className="accordion-item-text">
            <p>
              Our brush removal service clears overgrown vegetation for safer,
              more appealing properties. Perfect for dense landscapes, fire
              hazards, or construction prep. We work with you to transform your
              space, making it ready for your project.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" className="accordion-item">
          <Accordion.Header className="accordion-item-header">
            Land Clearing
          </Accordion.Header>
          <Accordion.Body className="accordion-item-text">
            <p>
              Land clearing is a comprehensive service that involves the removal
              of vegetation, small trees, bushes, rocks, and other obstacles
              from a designated area of land. Our professional land clearing
              service is committed to delivering a clear and usable canvas for
              your specific project needs.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" className="accordion-item">
          <Accordion.Header className="accordion-item-header">
            Stump Grinding
          </Accordion.Header>
          <Accordion.Body className="accordion-item-text">
            <p>
              Stump removal erases tree remains after cutting. We use potent
              tools like grinders to turn stumps into chips, ensuring full
              elimination. Reclaim space, avoid risks, and regrowth. Our skilled
              team ensures safety, leaving your property smooth for landscaping.
              Bye to stumps with our reliable service.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3" className="accordion-item">
          <Accordion.Header className="accordion-item-header">
            Small Tree Removal
          </Accordion.Header>
          <Accordion.Body className="accordion-item-text">
            <p>
              For small tree removal, rely on us. Our skilled team uses advanced
              equipment to safely cut and remove smaller trees, considering
              surroundings. From pruning to total removal, our eco-friendly
              solutions match your needs, leaving your property neat. Count on
              our professionalism, precision, and environmental care.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default Demo;
