import React from "react";

const CallBar = ({ children }) => {
    return(
  
  <div className="scrolling-text-container">
    <div className="scrolling-text">Call for a free estimate 
    <a href="" className="scrolling-text-num">(716)-523-3807</a></div>
    
  </div>
  
  
    );
  };
  
  export default CallBar;