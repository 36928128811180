import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Modal from "react-bootstrap/Modal"; // Import Modal component
import logo from "../img/Hooks Small Tree Stump Grinding Khawan FINAL.png";

function MainNav() {
  const [showModal, setContactModal] = useState(false);
  const [showAboutModal, setAboutModal] = useState(false);

  const handleContactModalOpen = () => {
    setContactModal(true);
  };

  const handleContactModalClose = () => {
    setContactModal(false);
  };

  const handleAboutModalOpen = () => {
    setAboutModal(true);
  };

  const handleAboutModalClose = () => {
    setAboutModal(false);
  };

  return (
    <>
      <Navbar expand="lg" className="nav-main">
        <Container>
          <div className="logo">
            <img src={logo} className="logoimg" alt="Logo"></img>
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="nav-links">
              <Nav.Link className="link" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="link" onClick={handleAboutModalOpen}>
                About
              </Nav.Link>
              <Nav.Link className="link" href="/gallery">
                Gallery
              </Nav.Link>
              <Nav.Link className="link" onClick={handleContactModalOpen}>
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Modal component */}
      <Modal show={showModal} size='lg' onHide={handleContactModalClose}>
        <Modal.Header closeButton className="contact-box">
          <Modal.Title className="contact-title">
            Contact Us for a Free Estimate
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="contact-info">
          By Phone: <a href="tel:(716)-523-3807">(716)-523-3807</a>
          <br />
          By email:{" "}
          <a href="mailto:blizzyblack4@gmail.com">blizzyblack4@gmail.com</a>
        </Modal.Body>
        <Modal.Footer className="contact-box">
          <button
            className="btn-btn-secondary"
            onClick={handleContactModalClose}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAboutModal} size='lg' onHide={handleAboutModalClose}>
        <Modal.Header closeButton className="contact-box">
          <Modal.Title className="contact-title">About Us</Modal.Title>
        </Modal.Header>
        <Modal.Body className="contact-info">
          <p>
            Welcome to our trusted local business specializing in small tree
            removal and stump grinding. With a commitment to excellence, we
            blend skillful techniques and modern equipment to seamlessly enhance
            your property.{" "}
          </p>
          <p>
            Our experienced team ensures the meticulous removal of small trees
            while our stump grinding service erases any trace of them, leaving
            you with safer, more beautiful surroundings.{" "}
          </p>
          <p>
            Count on us to bring a professional touch to every project, catering
            to your specific needs and exceeding your expectations.
          </p>
        </Modal.Body>
        <Modal.Footer className="contact-box">
          <button className="btn-btn-secondary" onClick={handleAboutModalClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MainNav;
