import React from "react";
import { Container, Row, Col, Image, Carousel } from "react-bootstrap";

const PhotoParent = () => {
  // Generate image URLs based on folder structure
  const generateImageURLs = (folderName, imageCount) => {
    const imageURLs = [];
    for (let i = 1; i <= imageCount; i++) {
      const imageNumber = i < 10 ? `${i}` : i;
      const imageUrl = require(`../img/${folderName}/image${imageNumber}.jpg`);
      imageURLs.push(imageUrl);
    }
    return imageURLs;
  };

  // Example folder names and image counts
  const folders = ["G0", "G1", "G2", "G3", "G4", "G5", "G6", "G7", "G8", "G9"];
  const imagesPerFolder = [4, 5, 6, 4, 9, 6, 5, 6, 6, 6]; // Adjust counts for each folder

  return (
    <Container className="photo-carousel">
      {folders.map((folder, index) => (
        <Row key={index} className="mb-4">
          <Col xs={12}>
            <Carousel interval={null} className="photo-carousel">
              {generateImageURLs(folder, imagesPerFolder[index]).map(
                (imageUrl, i) => (
                  <Carousel.Item key={i}>
                    <Image
                      src={imageUrl}
                      alt={`Slide ${i}`}
                      className="d-block w-100"
                    />
                    {/* <Carousel.Caption> */}
                    {/* <h3>{`Slide ${i + 1}`}</h3> */}
                    {/* <p>{`This is the caption for slide ${i + 1}.`}</p> */}
                    {/* </Carousel.Caption> */}
                  </Carousel.Item>
                )
              )}
            </Carousel>
          </Col>
        </Row>
      ))}
    </Container>
  );
};

export default PhotoParent;
